import { Link } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
import { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Checkboxes
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Timer from "./Timer";
import QuizInfo from "./QuizInfo";
import { useGetQuestionsQuery } from "../questions/questionsApiSlice";

import { useAddNewQuizMutation, useUpdateQuizMutation } from "./quizSlice";
import { useAddNewQuizQuestionMutation } from "./quizQuestionSlice";
import useAuth from "../../hooks/useAuth";
import { useUpdateUserMutation } from "../users/usersApiSlice";
import { useDispatch } from "react-redux";
import { userCompletedQuiz } from "../auth/authSlice";
import Questionnaire from "../users/UserExperienceInfo";

const Quiz1 = () => {
  // const { username, isManager, isAdmin } = useAuth();
  const {
    data: dataUnprocessed,
    isLoading,
    isSuccess,
    isError,
    error,
    // } = useGetUsersQuery(undefined, {
  } = useGetQuestionsQuery("questionsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  let onlyLoadOnceQuestions = false;

  const { userID, isAdmin, isManager, isUniversal } = useAuth();
  // const [addNewQuestion, { isLoading, isSuccess, isError, error }] =
  //     useAddNewQuestionMutation();

  // Redux Quiz
  const [
    addNewQuiz,
    { isLoadingAddQuiz: quizLoading, isSuccessAddQuiz: quizSuccess },
  ] = useAddNewQuizMutation();
  const [
    updateQuiz,
    { isLoadingUpdateQuiz: updateLoading, isSuccessUpdateQuiz: updateSuccess },
  ] = useUpdateQuizMutation();

  // Redux QuizQuestion
  const [
    addNewQuizQuestion,
    {
      isLoadingAddQuizQuestion,
      isSuccessAddQuizQuestion,
      isErrorAddQuizQuestion,
      errorAddQuizQuestion,
    },
  ] = useAddNewQuizQuestionMutation();

  const [updateUser] = useUpdateUserMutation();

  const [start, setStart] = useState(false);
  const [quizID, setQuizID] = useState("");
  let [finished, setFinished] = useState(false);
  let [sampleQuestionsFinished, setSampleQuestionsFinished] = useState(false);
  let [samplesLeft, setSamplesLeft] = useState(10);

  let [questionNr, setQuestionNr] = useState(1);
  const [question, setQuestion] = useState(null);
  let [correctLevel1, setCorrectLevel1] = useState(0);
  let [correctLevel2, setCorrectLevel2] = useState(0);
  let [correctLevel3, setCorrectLevel3] = useState(0);
  let [answeredLevel1, setAnsweredLevel1] = useState(0);
  let [answeredLevel2, setAnsweredLevel2] = useState(0);
  let [answeredLevel3, setAnsweredLevel3] = useState(0);
  let [currentLevel, setCurrentLevel] = useState(2);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [endDate, setEndDate] = useState(Date.now());

  const [answer, setAnswer] = useState("");
  let [multipleAnswerString, setMultipleAnswerString] = useState("");

  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const [multipleAnswer, setMultipleAnswer] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false,
  });
  const multipleAnswersString = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
  ];

  // Questions Picker Refactor
  let [questionsNewOrder, setQuestionsNewOrder] = useState([5, 4, 3, 2]);
  let [questions, setQuestions] = useState({});

  const timerRef = useRef();
  const dispatch = useDispatch();

  let entities;
  sessionStorage.setItem("modalDisplayed", "true");

  const getRandomSublevel = (min, max) => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
  };

  useEffect(() => {
    if (isSuccess && !onlyLoadOnceQuestions) {
      entities = Object.values(dataUnprocessed.entities).filter(
        (question) => question?._id
      );
      let lvl1 = [];
      let lvl2 = [];
      let lvl3 = [];
      let sample_questions = [];
      // console.log("Entities: ", JSON.parse(JSON.stringify(entities)));
      Object.values(dataUnprocessed.entities).map((question) => {
        !question?.new_question
          ? question?.level === 1
            ? lvl1.push(question)
            : question?.level === 2
            ? lvl2.push(question)
            : lvl3.push(question)
          : sample_questions.push(question);
      });
      let lvl11 = { 1: [], 2: [], 3: [], 4: [], 5: [] };
      let lvl22 = { 1: [], 2: [], 3: [], 4: [], 5: [] };
      let lvl33 = { 1: [], 2: [], 3: [], 4: [], 5: [] };
      let lvlM1 = { 1: [] };
      lvl1.forEach((question) => {
        lvl11[question["subLevel"]].push(question);
      });
      lvl2.forEach((question) => {
        lvl22[question["subLevel"]].push(question);
      });
      lvl3.forEach((question) => {
        lvl33[question["subLevel"]].push(question);
      });
      lvlM1[1] = sample_questions.sort((a, b) => a["count"] - b["count"]);

      let newQuestions = {
        0: lvlM1, // Sample Questions
        1: lvl11,
        2: lvl22,
        3: lvl33,
      };
      // setQuestions(lvl2);
    //   console.log("Questions: ", JSON.parse(JSON.stringify(newQuestions)));

      // Get random question from lvl 2 and then remove it
      let currentSublevel = getRandomSublevel(
        0,
        newQuestions[2][1].length
      );
      let currentQuestion = newQuestions[2][1][currentSublevel];
      newQuestions[2][1].splice(currentSublevel, 1);
      setQuestions(newQuestions);
      setQuestion(currentQuestion);
      onlyLoadOnceQuestions = true;
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      let answersTemp = [];
      for (let key in question) {
        if (key.startsWith("answer")) {
          answersTemp.push(question[key]);
        }
      }
      setAnswers(answersTemp);
    }
  }, [question]);

  useEffect(() => {
    if(!sampleQuestionsFinished){
      setEndDate(Date.now());
    }
  }, [finished])

  const handleAnswerChange = (event) => {
    setAnswer((event.target).value);
  };

  const isAnswerCorrect = () => {
    let returnedAnswer = false;
    if (!question?.multiple_answer_p) {
      returnedAnswer = answer
        ? question?.correct_answer === (parseInt(answer) + 1).toString()
        : false;
    } else {
      let tempAns = "";
      if (multipleAnswer.first) {
        tempAns += "1,";
      }
      if (multipleAnswer.second) {
        tempAns += "2,";
      }
      if (multipleAnswer.third) {
        tempAns += "3,";
      }
      if (multipleAnswer.fourth) {
        tempAns += "4,";
      }
      if (multipleAnswer.fifth) {
        tempAns += "5,";
      }
      if (multipleAnswer.sixth) {
        tempAns += "6,";
      }
      returnedAnswer =
        tempAns != "" && question?.correct_answer === tempAns.slice(0, -1);

      multipleAnswerString = tempAns.slice(0, -1);
      setMultipleAnswerString(multipleAnswerString);
    }
    return returnedAnswer;
  };

  const refillQuestionsOrderIfNeeded = (isLevelChanged) => {
    if (isLevelChanged) {
      switch (currentLevel) {
        case 1:
          questionsNewOrder = [5, 3, 1, 4, 2];
          setQuestionsNewOrder([5, 3, 1, 4, 2]);
          break;
        case 3:
          questionsNewOrder = [5, 1, 4, 3, 2];
          setQuestionsNewOrder([5, 1, 4, 3, 2]);
          break;
        case 2:
          console.error("Error: Level 2 should not be here.");
          break;
      }
    } else {
      if (questionsNewOrder.length === 0) {
        questionsNewOrder = [5, 4, 3, 2, 1];
        setQuestionsNewOrder([5, 4, 3, 2, 1]);
      }
    }
  };

  const determineLevelMainQuiz = async () => {
    if (currentLevel === 2) {
      if (correctLevel2 >= 12) {
        console.log("##ADVANCE TO LEVEL 3##");
        currentLevel = 3;
        setCurrentLevel(3);
        refillQuestionsOrderIfNeeded(true);
      } else if (answeredLevel2 - correctLevel2 >= 10) {
        console.log("##BACK TO LEVEL 1##");
        currentLevel = 1;
        setCurrentLevel(1);
        refillQuestionsOrderIfNeeded(true);
      } else if (answeredLevel2 >= 18 && correctLevel2 === 10) {
        finished = true;
        setFinished(true);
      } else {
        refillQuestionsOrderIfNeeded(false);
      }
    } else if (currentLevel === 1) {
      if (answeredLevel1 - correctLevel1 >= 2) {
        finished = true;
        setFinished(true);
      } else if (correctLevel1 >= 10) {
        currentLevel = 2;
        setCurrentLevel(2);
        finished = true;
        setFinished(true);
      } else {
        refillQuestionsOrderIfNeeded(false);
      }
    } else if (currentLevel === 3) {
      if (correctLevel3 >= 3) {
        finished = true;
        setFinished(true);
      } else if (answeredLevel3 - correctLevel3 >= 12) {
        currentLevel = 2;
        setCurrentLevel(2);
        finished = true;
        setFinished(true);
      } else {
        refillQuestionsOrderIfNeeded(false);
      }
    }
  };

  const determineLevelSampleQuiz = async () => {
    if (samplesLeft === 0 || questions[0][1].length === 0) {
      sampleQuestionsFinished = true;
      setSampleQuestionsFinished(true);
      await finishQuizBackend();
    }
  };

  const determineNextQuestionMainQuiz = () => {
    // Last
    setQuestionNr(questionNr + 1);

    let currentSublevel = -1;
    let currentQuestion = null;
    let getNewQuestionIndex = -1;
    do {
      refillQuestionsOrderIfNeeded(false);
      currentSublevel = questionsNewOrder.pop();
      setQuestionsNewOrder(questionsNewOrder);
      if (questions[currentLevel][currentSublevel].length === 0) continue;
      getNewQuestionIndex = getRandomSublevel(
        0,
        questions[currentLevel][currentSublevel].length
      );
      currentQuestion =
        questions[currentLevel][currentSublevel][getNewQuestionIndex];
    } while (!currentQuestion);
    // Delete the question from the array
    questions[currentLevel][currentSublevel].splice(getNewQuestionIndex, 1);
    setQuestions(questions);
    setQuestion(currentQuestion);

    setAnswer("");
    setMultipleAnswerString("");
    setMultipleAnswer({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
    });
  };

  const determineNextQuestionSampleQuiz = () => {
    setQuestionNr(questionNr + 1);
    
    if (questions[0][1].length === 0) {
      setSampleQuestionsFinished(true);
      return;
    }
    const getNewQuestionIndex = getRandomSublevel(0, questions[0][1].length);
    const currentQuestion = questions[0][1][getNewQuestionIndex];
    questions[0][1].splice(getNewQuestionIndex, 1);
    setQuestions(questions);
    setQuestion(currentQuestion);
    samplesLeft -= 1;
    setSamplesLeft(samplesLeft);

    setAnswer("");
    setMultipleAnswerString("");
    setMultipleAnswer({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
    });
  };

  const setCurrentAnswer = async () => {
    const currentAnswer = {
      question: question,
      correct: isAnswerCorrect(question, answers),
      answer: !question?.multiple_answer_p
        ? (parseInt(answer) + 1).toString()
        : multipleAnswerString,
      sample: question?.new_question,
    };
    const currentAnswerBackend = {
      user: userID,
      question: question?._id,
      quiz: quizID,
      correct: currentAnswer.correct,
      answer: currentAnswer.answer,
      sample: currentAnswer.sample,
      order: questionNr,
    };

    if (
      !currentAnswer ||
      !currentAnswer.answer ||
      currentAnswer.answer === "NaN"
    ) {
      delete currentAnswerBackend.answer;
    }

    await addNewQuizQuestion(currentAnswerBackend).then((response) => {
      if (response && response?.data) {
        // console.log("Answer added to the backend.");
      } else {
        console.log("Error: Answer not added to the backend.");
      }
    });

    answeredQuestions.push(currentAnswer);
    setAnsweredQuestions(answeredQuestions);
    if (currentAnswer.correct) {
      switch (question?.level) {
        case 1:
          correctLevel1 += 1;
          setCorrectLevel1(correctLevel1);
          break;
        case 2:
          correctLevel2 += 1;
          setCorrectLevel2(correctLevel2);
          break;
        case 3:
          correctLevel3 += 1;
          setCorrectLevel3(correctLevel3);
          break;
        default:
          console.log("Error: Level not found for the current question.");
          break;
      }
    }
    // question?.level === 1
    //     ? setAnsweredLevel1(answeredLevel1 + 1)
    //     : question?.level === 2
    //     ? setAnsweredLevel2(answeredLevel2 + 1)
    //     : setAnsweredLevel3(answeredLevel3 + 1);
    if (question?.level === 1) {
      answeredLevel1 += 1;
      setAnsweredLevel1(answeredLevel1);
    } else if (question?.level === 2) {
      answeredLevel2 += 1;
      setAnsweredLevel2(answeredLevel2);
    } else {
      answeredLevel3 += 1;
      setAnsweredLevel3(answeredLevel3);
    }
  };

  const resetTimer = () => {
    timerRef.current?.resetTimer();
  };

  // Next Button
  let onNextQuestion = async () => {
    await setCurrentAnswer();
    if (!finished) await determineLevelMainQuiz();
    else await determineLevelSampleQuiz();

    if (!finished) determineNextQuestionMainQuiz();
    else determineNextQuestionSampleQuiz();

    resetTimer();
  };

  let timerOver = () => {
    // console.log("Timer Over");
    onNextQuestion();
  };

  const handleMultipleAnswersCheck = (
    event
  ) => {
    setMultipleAnswer({
      ...multipleAnswer,
      [event.target.name]: event.target.checked,
    });
  };

  const onAcceptConditions = async () => {
    setShowQuestionnaire(true);
  };

  const finishQuizBackend = async () => {
    try {
      await updateQuiz({
        id: quizID,
        user: userID,
        score: currentLevel,
        end_date: endDate,
      });
      if(!isAdmin && !isManager && !isUniversal){
        await updateUser({
          id: userID,
          active: false,
        });
        dispatch(userCompletedQuiz());

      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const startQuizCallback = async (data) => {
    await addNewQuiz({
      ...data,
      user: userID,
    }).then((response) => {
      if (response && response?.data) {
        setQuizID(response.data._id);
      }
    });

    setShowQuestionnaire(false);
    setStart(true);
  };

  const content = (
    <section className="welcome">
      {showQuestionnaire && (<Questionnaire startQuizCallback={startQuizCallback}/>)}
      {/* Quiz Layout */}

      {/* Write me the below with css that puts it in center of the screen, with colors. */}
      {/* {finished && (
                <>The test has finished! You have level {currentLevel}.</>
            )} */}
      {!showQuestionnaire && !start && (
        <QuizInfo
          onAccept={onAcceptConditions}
          isLoading={isLoading}
          isError={isError}
        />
      )}
      {finished && sampleQuestionsFinished && (
        <div className="finished">
          <div className="finished__main">
            <div className="finished__main-message">テストが終了しました！</div>
            <div className="finished__main-level">
              あなたの推定クリニカルラダーは
              <br />
              レベル{" "}
              <a className="finished__main-level--color">{currentLevel}</a>
              {currentLevel === 3 ? '以上' : null}です。
              <br />
              お疲れ様でした。
            </div>
          </div>
        </div>
      )}

      {/* Only for TESTING purposes */}
      {/* {start && (!finished || !sampleQuestionsFinished) && (
        <div className="finished">
          <div className="finished__main">
            <div className="finished__main-level">
              テストのレベルは{" "}
              <a className="finished__main-level--color">
                {finished && !sampleQuestionsFinished
                  ? "サンプル"
                  : currentLevel}
              </a>
              です。
            </div>
            <div>ID: {question?.id}</div>
            <div>正解：{question?.correct_answer}</div>
            {!finished && (
              <>
                問題のレベル: {question?.level} | サブレベル：
                {question?.subLevel}
              </>
            )}
          </div>
        </div>
      )} */}

      {start && (!finished || !sampleQuestionsFinished) && (
        <>
          <div className="quiz">
            <div className="quiz__main">
              <div className="quiz__main-section">
                <h1 className="quiz__main-title">問題＃{questionNr}</h1>
                <div className="quiz__main-content">
                  <div className="quiz__main-question">
                    {/* 病棟に入院している患者を日勤看護師に振り分ける際、経験のある看護師が優先的にもつべきと思う患者はどれですか。最も適切だと思うものを一つ選んで下さい。 */}
                    {question?.question}
                  </div>
                  <div className="quiz__main-answers">
                    {!question?.multiple_answer_p ? (
                      <FormControl className="quiz__main-answers">
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          答え
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={answer}
                          onChange={handleAnswerChange}
                        >
                          {answers.map((answer, index) => (
                            <FormControlLabel
                              key={index + 1}
                              value={index.toString()}
                              control={<Radio />}
                              label={answer}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <Box sx={{ display: "flex" }}>
                        <FormControl
                          sx={{ m: 3 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormLabel component="legend">答え</FormLabel>
                          <FormGroup>
                            {answers.map((answer, index) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={multipleAnswer[index]}
                                    onChange={handleMultipleAnswersCheck}
                                    name={multipleAnswersString[index]}
                                  />
                                }
                                label={answer}
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={
                answer || Object.values(multipleAnswer).some((el) => el)
                  ? buttonStyle
                  : buttonStyleDisabled
              }
              onClick={onNextQuestion}
            >
              次の質問
            </Button>
          </div>
          <Timer overFc={timerOver} ref={timerRef} />
        </>
      )}
    </section>
  );

  return content;
};
export default Quiz1;

const buttonStyle = {
  backgroundColor: "#1E90FF",
  color: "white",
  padding: "10px",
  margin: "10px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "25%",
};

const buttonStyleDisabled = {
  backgroundColor: "#1E90FF",
  color: "white",
  padding: "10px",
  margin: "10px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "25%",
  opacity: "0.5",
  pointerEvents: "none",
};
