import React from "react";
import { useNavigate } from "react-router-dom";
import "./QuizView.css";
import useAuth from "../../hooks/useAuth";

const QuizView = ({ quiz }) => {
  const { isManager, isAdmin } = useAuth();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (isManager || isAdmin) navigate(`/dash/users/view/${quiz.user}`);
    else navigate(`/dash`);
  };

  // Check once if an order key exists; if so, sort the questions by it
  const sortedQuestions = React.useMemo(() => {
    if (
      quiz.questions &&
      quiz.questions.length > 0 &&
      quiz.questions[0].order !== undefined
    ) {
      return [...quiz.questions].sort((a, b) => a.order - b.order);
    }
    return quiz.questions;
  }, [quiz.questions]);

  return (
    <div className="quiz-container">
      <div className="header-container">
        <div className="quiz-info">
          <p>
            <strong>日付:</strong> {new Date(quiz.date).toLocaleString()}
          </p>
          <p>
            <strong>ラダー:</strong> {quiz.score}
          </p>
          <p>
            <strong>ステータス:</strong> {quiz.finished ? "完成" : "未完成"}
          </p>
          <div className="additional-info">
            <p>
              #1: {quiz.experience_total}, #2:{" "}
              {quiz.experience_current_hospital}, #3: {quiz.position}, #4:{" "}
              {quiz.institution_type}, #5: {quiz.number_of_beds}, #6:{" "}
              {quiz.institution_ladder}, #6a:{" "}
              {quiz.institution_ladder_five_level ?? "N/A"}, #6b:{" "}
              {quiz.institution_ladder_four_level ?? "N/A"}, #7: {quiz.clinical_ladder}
            </p>
          </div>
        </div>
        <button onClick={handleGoBack} className="go-back-button">
          戻る
        </button>
      </div>

      {sortedQuestions.map((q, index) => {
        const correctAnswers = q.question.correct_answer
          .split(",")
          .map((ans) => ans.trim());
        const userAnswers = q.answer.split(",").map((ans) => ans.trim());

        return (
          <div key={q._id} className="question-container">
            <p className="question-text">
              {q.question.id}|{index + 1}. {q.question.question}
            </p>

            {["1", "2", "3", "4", "5", "6"].map((opt) => {
              const answerKey = `answer${opt}`;
              const answerText = q.question[answerKey];

              if (!answerText) {
                return null; // Skip undefined answers
              }

              const isCorrect = correctAnswers.includes(opt);
              const isUserAnswer = userAnswers.includes(opt);

              let className = "answer";
              if (isCorrect && isUserAnswer) {
                className = "answer correct-answer"; // Green for correct answers
              } else if (isCorrect && !isUserAnswer) {
                className = "answer missing-answer"; // Yellow for correct but not answered
              } else if (!isCorrect && isUserAnswer) {
                className = "answer wrong-answer"; // Red for wrong answers
              }

              return (
                <p key={opt} className={className}>
                  {answerText}
                  {isCorrect ? " ✓" : isUserAnswer ? " ✗" : ""}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default QuizView;
